<template>
    <standard-page :definition="$DEFINITIONS.provenance.processSteps" :status="process.status" :sub_title="'Process Steps'" :title="process.name" v-if="process">
        <template v-slot:content>
            <div class="wizard clearfix vertical d-none d-xl-block" v-if="active_steps.length>0">
                <div class="d-none d-xl-block steps custom-border-right clearfix">
                    <ul role="tablist">
                        <li class="head disabled" v-bind:key="index" v-for="(process_step, index) in active_steps">
                            <a :aria-controls="'wizard1-p-'+index" :id="'wizard1-t-'+index"
                               @click="setStepIndex(process_step.order, process_step.process_definition_step, process_step.process_step, process_step.status, process_step.attested_at, process_step.attestors, process_step.name)"
                               v-bind:href="'#wizard1-h-'+index">
                                <span :class="{'active': clicked_step_index == index}" class="number" style="cursor: pointer">{{ index + 1 }}</span>
                                <span :class="{'active': clicked_step_index == index}" class="title" style="cursor: pointer" v-if="process_step">{{ process_step.name | truncate(20, '.....') }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="content clearfix">
                    <form @submit.prevent="handleSubmit">
                        <h3 class="mg-b-20 title current" v-if="current_step_name">{{ current_step_name }}</h3>
                        <section class="body current">
                            <template>
                                <div class="row">
                                    <div class="col-12" v-if="!updating">
                                        <div :id="'accordionid'+j" aria-multiselectable="true" class="accordion" role="tablist" v-for="(group, j) in group_attributes" v-if="group_attributes">
                                            <fieldset>
                                                <legend>
                                                    <span class="font-weight-bold" style="font-size: 17px">{{ group.group }}</span>
                                                    <span class="text-right float-right" v-if="process_step_status !== 'Attested'">
                                                        <a @click="addEntry(j)" class="btn btn-sm btn-outline-indigo btn-block" href="javascript:void(0)">Add Record</a>
                                                    </span>
                                                </legend>
                                                <div class="card" v-bind:key="i" v-for="(set, i) in group.sets">
                                                    <div :id="'header'+j+i" class="card-header" role="tab">
                                                        <a :aria-controls="'collapseOne_'+j+i" :class="{ 'collapsed': !shouldExpandGroup(i) }"
                                                           aria-expanded="true" data-toggle="collapse" v-bind:href="'#collapseOne_'+j+i">
                                                            Record #{{ i + 1 }}

                                                            <button v-if="i !== 0 && process_step_status !== 'Attested'" @click="removeEntry(j, i, set.fields)" class="btn disabled float-right mt-n2"><i class="fa fa-trash"></i></button>
                                                        </a>
                                                    </div><!-- card-header -->

                                                    <div :aria-labelledby="'header'+j+i" :class="{ 'show': shouldExpandGroup(i) }"
                                                         :data-parent="'#accordionid'+j"
                                                         :id="'collapseOne_'+j+i" class="collapse" role="tabpanel">
                                                        <div class="card-body">
                                                            <div class="form-group row mb-3" v-bind:key="index1" v-for="(a, index1) in set.fields">
                                                                <label :for="a.attribute+a.name" class="col-md-3 col-form-label">{{ a.name.substring(0, a.name.lastIndexOf('(')) }}
<!--                                                                    &nbsp;<small>({{a.private? 'Private' : 'Public'}})</small>-->
                                                                </label>
                                                                <div class="col-md-9">
                                                                    <input :class="{'is-invalid': submitted && errors.has('attr'+j+i+index1)}" :data-vv-as="a.name" :id="'attr'+j+i+index1"
                                                                           :name="'attr'+j+i+index1" :readonly="process_step_status === 'Attested'" class="form-control" type="text"
                                                                           v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Text')" v-model="a.fact.value"
                                                                           v-validate="{ required: a.required }">
                                                                    <input :class="{'is-invalid': submitted && errors.has('attr'+j+i+index1)}" :data-vv-as="a.name" :id="'attr'+j+i+index1"
                                                                           :name="'attr'+j+i+index1" :readonly="process_step_status === 'Attested'" class="form-control" type="number" step="any"
                                                                           v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Float')" v-model="a.fact.value"
                                                                           v-validate="{ required: a.required }">
                                                                    <select :class="{'is-invalid': submitted && errors.has('attr'+j+i+index1)}" :data-vv-as="a.name" :disabled="a.readonly"
                                                                            :id="'attr'+j+i+index1" :name="'attr'+j+i+index1" class="form-control custom-select"
                                                                            v-if="a.values && a.values.length >0 && process_step_status !== 'Attested'" v-model="a.fact.value"
                                                                            v-validate="{ required: a.required }">
                                                                        <option :value="null" selected>Select {{ a.name }}</option>
                                                                        <option :key="val" :value="val" v-for="val in a.values">
                                                                            {{ val }}
                                                                        </option>
                                                                    </select>
                                                                    <input :class="{'is-invalid': submitted && errors.has('attr'+j+i+index1)}" :data-vv-as="a.name" :id="'attr'+j+i+index1"
                                                                           :name="'attr'+j+i+index1" :readonly="process_step_status === 'Attested'" class="form-control" type="date"
                                                                           v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Date' || a.fact.type === 'DateTime')" v-model="a.fact.value"
                                                                           v-validate="{ required: a.required }">
                                                                    <input :class="{'is-invalid': submitted && errors.has('attr'+j+i+index1)}" :data-vv-as="a.name" :id="'attr'+j+i+index1"
                                                                           :name="'attr'+j+i+index1" :readonly="process_step_status === 'Attested'" class="form-control" type="number"
                                                                           v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Integer' || a.fact.type === 'U128')" v-model="a.fact.value"
                                                                           v-validate="{ required: a.required }">
                                                                    <div class="invalid-feedback" v-if="submitted && errors.has('attr'+j+i+index1)">
                                                                        {{ errors.first('attr' + j + i + index1) }}
                                                                    </div>
                                                                    <div class="mt-2" v-else-if="(!a.values || a.values.length === 0) && a.fact.type === 'Bool'">
                                                                        <label class="rdiobox">
                                                                            <input :class="{'is-invalid': submitted && errors.has('attr'+j+i+index1)}" :id="'attr'+j+i+index1" :name="'attr'+j+i+index1"
                                                                                   :readonly="process_step_status === 'Attested'" type="radio" v-bind:value=true v-model="a.fact.value"
                                                                                   v-validate="{ required: a.required }">
                                                                            <span>Yes</span>
                                                                            <div class="invalid-feedback" v-if="submitted && errors.has('attr'+j+i+index1)">
                                                                                {{ errors.first('attr' + j + i + index1) }}
                                                                            </div>
                                                                        </label>
                                                                        <label class="rdiobox">
                                                                            <input :class="{'is-invalid': submitted && errors.has('attr'+j+i+index1)}" :id="'attr'+j+i+index1" :name="'attr'+j+i+index1"
                                                                                   :readonly="process_step_status === 'Attested'" type="radio" v-bind:value=false v-model="a.fact.value"
                                                                                   v-validate="{ required: a.required }">
                                                                            <span>No</span>
                                                                            <div class="invalid-feedback" v-if="submitted && errors.has('attr'+j+i+index1)">
                                                                                {{ errors.first('attr' + j + i + index1) }}
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div><!-- accordion -->
                                        <div class="form-group row mb-3 mt-3" v-for="(a, index1) in repeatable_attributes" v-if="process_step_status !== 'Attested'">
                                            <label :for="a.attribute+a.name" class="col-md-3 col-form-label">{{ a.name }}
<!--                                                &nbsp;<small>({{a.private? 'Private' : 'Public'}})</small>-->
                                            </label>
                                            <div class="input-group col-md-9">
                                                <input :class="{'is-invalid': submitted && errors.has('repeat_attr'+index1)}" :data-vv-as="a.name" :id="'repeat_attr'+index1"
                                                       :name="'repeat_attr'+index1" :readonly="process_step_status === 'Attested'" class="form-control" type="text"
                                                       v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Text')" v-model="a.fact.value" v-validate="{ required: a.required }">
                                                <input :class="{'is-invalid': submitted && errors.has('repeat_attr'+index1)}" :data-vv-as="a.name" :id="'repeat_attr'+index1"
                                                       :name="'repeat_attr'+index1" :readonly="process_step_status === 'Attested'" class="form-control" type="number"  step="any"
                                                       v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Float')" v-model="a.fact.value" v-validate="{ required: a.required }">
                                                <select :class="{'is-invalid': submitted && errors.has('repeat_attr'+index1)}" :data-vv-as="a.name" :disabled="a.readonly"
                                                        :id="'repeat_attr'+index1" :name="'repeat_attr'+index1" class="form-control custom-select"
                                                        v-if="a.values && a.values.length >0 && process_step_status !== 'Attested'" v-model="a.fact.value" v-validate="{ required: a.required }">
                                                    <option :value="null" selected>Select {{ a.name }}</option>
                                                    <option :key="val" :value="val" v-for="val in a.values">
                                                        {{ val }}
                                                    </option>
                                                </select>
                                                <select :class="{'is-invalid': submitted && errors.has('repeat_attr'+index1)}" :data-vv-as="a.name" :disabled="a.readonly"
                                                        :id="'repeat_attr'+index1" :name="'repeat_attr'+index1" class="form-control custom-select" @change="openLinkedProcess(a.fact.value)"
                                                        v-if="a.fact.type === 'Definition' && process_step_status !== 'Attested'" v-model="a.fact.value" v-validate="{ required: a.required }">
                                                    <option :value="null" selected>Select {{ a.name }}</option>
                                                    <option :key="val.process" :value="val.process" v-for="val in linked_processes">{{ val.name }}</option>
                                                </select>
                                                <input :class="{'is-invalid': submitted && errors.has('repeat_attr'+index1)}" :data-vv-as="a.name" :id="'repeat_attr'+index1"
                                                       :name="'repeat_attr'+index1" :readonly="process_step_status === 'Attested'" class="form-control" type="date"
                                                       v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Date' || a.fact.type === 'DateTime')" v-model="a.fact.value"
                                                       v-validate="{ required: a.required }">
                                                <input :class="{'is-invalid': submitted && errors.has('repeat_attr'+index1)}" :data-vv-as="a.name" :id="'repeat_attr'+index1"
                                                       :name="'repeat_attr'+index1" :readonly="process_step_status === 'Attested'" class="form-control" type="number"
                                                       v-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Integer' || a.fact.type === 'U128')" v-model="a.fact.value"
                                                       v-validate="{ required: a.required }">
                                                <div class="invalid-feedback" v-if="submitted && errors.has('repeat_attr'+index1)">
                                                    {{ errors.first('repeat_attr' + index1) }}
                                                </div>
                                                <div class="mt-2" v-else-if="(!a.values || a.values.length === 0) && a.fact.type === 'Bool'">
                                                    <label class="rdiobox">
                                                        <input :class="{'is-invalid': submitted && errors.has('repeat_attr'+index1)}" :name="'repeat_attr'+index1"
                                                               :readonly="process_step_status === 'Attested'" type="radio" v-bind:value=true v-model="a.fact.value"
                                                               v-validate="{ required: a.required }">
                                                        <span>Yes</span>
                                                        <div class="invalid-feedback" v-if="submitted && errors.has('repeat_attr'+index1)">
                                                            {{ errors.first('repeat_attr' + index1) }}
                                                        </div>
                                                    </label>
                                                    <label class="rdiobox">
                                                        <input :class="{'is-invalid': submitted && errors.has('repeat_attr'+index1)}" :name="'repeat_attr'+index1"
                                                               :readonly="process_step_status === 'Attested'" type="radio" v-bind:value=false v-model="a.fact.value"
                                                               v-validate="{ required: a.required }">
                                                        <span>No</span>
                                                        <div class="invalid-feedback" v-if="submitted && errors.has('repeat_attr'+index1)">
                                                            {{ errors.first('repeat_attr' + index1) }}
                                                        </div>
                                                    </label>
                                                </div>
                                                <div class="input-group-append ml-2">
                                                    <div class="btn-icon-list">
                                                        <a v-if="hide(a)" href="javascript:void(0)" @click="addRepeatable(a)" class="btn btn-secondary btn-icon"><i
                                                                class="typcn typcn-document-add"></i></a>
                                                        <a href="javascript:void(0)" v-if="getIndex(a) > 1" @click="removeRepeatable(index1, a)" class="btn btn-danger btn-icon"><i
                                                                class="typcn typcn-trash"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row mb-3 mt-3" v-bind:key="index1" v-for="(a, index1) in attributes">
                                            <label :for="a.attribute+a.name" class="col-md-3 col-form-label">{{ a.name }}
<!--                                                &nbsp;<small>({{a.private? 'Private' : 'Public'}})</small>-->
                                            </label>
                                            <div class="col-md-9">
                                                <div class="mt-2" v-if="isUrl(a.fact.value) && process_step_status === 'Attested'">
                                                    <a target="_blank" v-bind:href="a.fact.value">View Details</a>
                                                </div>
                                                <div class="mt-2" v-else-if="a.fact.type === 'Location' && process_step_status === 'Attested'">
                                                    <a target="_blank" v-bind:href="`https://www.google.com/maps/search/${a.fact.value}`">View on Map</a>
                                                </div>
                                                <input :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :id="'attribute_value1'+index1"
                                                       :name="'attribute_value1'+index1" :readonly="process_step_status === 'Attested'" class="form-control" type="text"
                                                       v-else-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Text') && !a.document_id"
                                                       v-model="a.fact.value" v-validate="{ required: a.required }">
                                                <input :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :id="'attribute_value1'+index1"
                                                       :name="'attribute_value1'+index1" :readonly="process_step_status === 'Attested'" class="form-control" type="number"  step="any"
                                                       v-else-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Float')" v-model="a.fact.value" v-validate="{ required: a.required }">
                                                <select :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :disabled="a.readonly"
                                                        :id="'attribute_value1'+index1" :name="'attribute_value1'+index1" class="form-control custom-select"
                                                        v-if="a.values && a.values.length >0 && process_step_status !== 'Attested'" v-model="a.fact.value" v-validate="{ required: a.required }">
                                                    <option :value="null" selected>Select {{ a.name }}</option>
                                                    <option :key="val" :value="val" v-for="val in a.values">
                                                        {{ val }}
                                                    </option>
                                                </select>
                                                <input :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :id="'attribute_value1'+index1"
                                                       :name="'attribute_value1'+index1" :readonly="process_step_status === 'Attested'" class="form-control" type="date"
                                                       v-else-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Date' || a.fact.type === 'DateTime')" v-model="a.fact.value"
                                                       v-validate="{ required: a.required }">
                                                <input :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :id="'attribute_value1'+index1"
                                                       :name="'attribute_value1'+index1" :readonly="process_step_status === 'Attested'" class="form-control" type="number"
                                                       v-else-if="(!a.values || a.values.length === 0) && (a.fact.type === 'Integer' || a.fact.type === 'U128')" v-model="a.fact.value"
                                                       v-validate="{ required: a.required }">
                                                <div class="invalid-feedback" v-if="submitted && errors.has('attribute_value1'+index1)">
                                                    {{ errors.first('attribute_value1' + index1) }}
                                                </div>
                                                <div class="mt-2" v-else-if="(!a.values || a.values.length === 0) && a.fact.type === 'Bool'">
                                                    <label class="rdiobox">
                                                        <input :class="{'is-invalid': submitted && errors.has(index1+'_yesno')}" :name="index1+'_yesno'"
                                                               :readonly="process_step_status === 'Attested'" type="radio" v-bind:value=true v-model="a.fact.value"
                                                               v-validate="{ required: a.required }">
                                                        <span>Yes</span>
                                                        <div class="invalid-feedback" v-if="submitted && errors.has(index1+'_yesno')">
                                                            {{ errors.first(index1 + '_yesno') }}
                                                        </div>
                                                    </label>
                                                    <label class="rdiobox">
                                                        <input :class="{'is-invalid': submitted && errors.has(index1+'_yesno')}" :name="index1+'_yesno'"
                                                               :readonly="process_step_status === 'Attested'" type="radio" v-bind:value=false v-model="a.fact.value"
                                                               v-validate="{ required: a.required }">
                                                        <span>No</span>
                                                        <div class="invalid-feedback" v-if="submitted && errors.has(index1+'_yesno')">
                                                            {{ errors.first(index1 + '_yesno') }}
                                                        </div>
                                                    </label>
                                                </div>
                                                <div class="custom-file" v-else-if="a.fact.type === 'File' && !a.document_id">
                                                    <input :id="'attribute_value1'+index1" :name="'attribute_value1'+index1"
                                                           @change="fileChange($event, index1)" class="custom-file-input" type="file">
                                                    <label v-if="!file_uploading" :for="index1" class="custom-file-label">{{ getfileName(index1) }}</label>
                                                </div>
                                                <div class="mt-2" v-else-if="a.fact.type === 'Definition'">
                                                    <a target="_blank" v-bind:href="`https://www.maya.explorer.borlaug.network/provenance/${a.fact.value}`">View Details</a>
                                                </div>
                                                <div class="custom-file" v-else-if="a.fact.type === 'File' && a.document_id">
                                                    <div class="input-group">
                                                        <input :value="a.document_name" class="form-control" placeholder="Search for..." readonly style="height: 40px" type="text">
                                                        <span class="input-group-btn">
                                                          <button @click="downloadAttachment(a.process_step, a.document_id, a.document_name)" class="btn btn-primary" type="button"><i
                                                                  class="fa fa-download"></i></button>
                                                          <button @click="removeAttachment(a.process_step, a.document_id, a.attribute, a.document_name)" class="btn btn-danger"
                                                                  type="button" v-if="process_step_status !== 'Attested'"><i class="fa fa-trash-alt"></i></button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="custom-file" v-else-if="a.fact.type === 'Location' && process_step_status !== 'Attested'">
                                                    <div class="input-group">
                                                        <input :class="{'is-invalid': submitted && errors.has('attribute_value1'+index1)}" :data-vv-as="a.name" :id="'attribute_value1'+index1"
                                                               :name="'attribute_value1'+index1" class="form-control" type="text" style="height: 40px"
                                                               v-model="a.fact.value" v-validate="{ required: a.required }">
                                                        <span class="input-group-btn">
                                                          <button title="Get Current Location" @click="getCurrentLocation(a)" class="btn btn-primary" type="button"><i class="fa fa-map-marked-alt"></i></button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4" v-if="process_step_status === 'Created' &&  attestor_step === clicked_step_index">
                                    <div class="col-sm-6">
                                        <a class="float-left text-left font-weight-semibold" target="_blank" v-bind:href="$BLOCK_EXPLORER+'/provenance/'+processid">
                                            <i class="fas fa-link"></i>&nbsp;View on Blockchain</a>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="text-right float-right">
                                            <button @click="button_clicked = 'Draft'" class="btn btn-primary" href="javascript:void(0)" type="submit">
                                                <i class="mdi mdi-content-save mr-1"></i> Save as Draft
                                            </button>
                                            <button @click="button_clicked = 'Confirm'" class="ml-1 btn btn-success" href="javascript:void(0)" type="submit">
                                                <i class="mdi mdi-arrow-right-bold-circle-outline mr-1"></i>
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4" v-else>
                                    <div class="col-sm-6">
                                        <a class="p-0 float-left font-weight-semibold mb-3" target="_blank" v-bind:href="$BLOCK_EXPLORER+'/provenance/'+processid">
                                            <i class="fas fa-link"></i>&nbsp;View on Blockchain</a>
                                    </div>
                                    <div class="col-sm-6 float-right text-right" v-if="attester">
                                        <p class="text-sm-right text-muted font-weight-semibold">Signed by&nbsp;<small class="text-muted">
                                            <a class="font-weight-semibold" target="_blank" v-bind:href="$BLOCK_EXPLORER+'/identity/did:bws:'+attester">
                                                <i class="fas fa-fingerprint"></i>&nbsp;did:bws:{{ attester | truncate(15, '....') }}</a>
                                        </small><br>on&nbsp;<small class="text-muted">{{ attested_at | shorttimestamp }}</small></p>
                                    </div>
                                </div> <!-- end row -->
                            </template>
                        </section>
                    </form>
                </div>
            </div>
            <div class="col-12 mg-t-20 text-center alert alert-warning alert-dismissible fade show" role="alert" v-else><strong>Warning - </strong> waiting for signer(s) of the
                previous step(s) to add information and signing !
            </div>
        </template>
    </standard-page>
</template>

<script>
import {mapGetters} from 'vuex';
import EventBus from '@/event-bus';
import _ from 'lodash';

export default {
    name: 'ProcessStepsWizard',
    props: ['registryid', 'processdefinitionid', 'processid'],
    computed: {
        ...mapGetters(['currentUser']),
        active_steps: function () {
            return this.process_steps.filter(process_step => process_step.order <= this.current_step && (this.attestor_step == this.current_step || process_step.order < this.current_step));
        }
    },
    data() {
        return {
            submitted: false,
            process: null,
            current_step: 0,
            process_status: null,
            process_step_status: null,
            process_steps: [],
            attestor_process_definition_step: null,
            step_to_update: null,
            attestor_step: null,
            clicked_step_index: 0,
            definition_attributes: [],
            definition_attributes_with_group: [],
            attributes: [],
            isCurrentStep: false,
            updating: false,
            attesting: false,
            attested_at: null,
            attester: null,
            current_step_name: null,
            group_attributes: [],
            button_clicked: null,
            process_attachment_reqs: [],
            process_attachments: [],
            current_location: null,
            repeatable_attributes: [],
            repeatable_attributes_bkp: [],
            file_uploading: false,
            attributes_to_be_removed: [],
            linked_definition: null,
            linked_processes: []
        }
    },
    mounted() {
        this.init();
        let self = this;
        navigator.geolocation.getCurrentPosition(function (location) {
            self.current_location = location.coords.latitude + ',' + location.coords.longitude;
            // console.log(location.coords.latitude);
            // console.log(location.coords.longitude);
            // console.log(location.coords.accuracy);
        });
    },
    methods: {
        openLinkedProcess(process){
           window.open(`https://www.maya.explorer.borlaug.network/provenance/${process}`)
        },
        isUrl(value) {
            let r = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
            return r.test(value);
        },
        getCurrentLocation(a) {
            navigator.geolocation.getCurrentPosition(function (location) {
                a.fact.value = location.coords.latitude + ',' + location.coords.longitude;
                // console.log(location.coords.latitude);
                // console.log(location.coords.longitude);
                // console.log(location.coords.accuracy);
            });
        },
        getfileName(index1) {
            return this.attributes[index1]['file_name'];
        },
        fileChange(e, index1) {
            this.file_uploading = true;
            let file = e.target.files[0];
            this.attributes[index1]['file_name'] = file.name;
            this.process_attachment_reqs = this.process_attachment_reqs.filter(a => a.name !== e.target.name && a.id !== index1);
            this.process_attachment_reqs.push({
                name: this.attributes[index1].name,
                file: file,
                id: index1
            });
            this.file_uploading = false;
        },
        async attachProcessDocs() {
            try {
                for (let i = 0; i < this.process_attachment_reqs.length; i++) {
                    let attachment = this.process_attachment_reqs[i];
                    let f = new FormData();
                    f.append('file', attachment.file);
                    await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${this.step_to_update}/documents?attribute_name=${attachment.name}`, f, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'BWS ' + localStorage.getItem('token')
                        }
                    })
                }
                this.process_attachment_reqs = [];
            } catch (e) {

            }
        },
        handleSubmit(e) {
            this.submitted = true;
            this.$validator.validate()
                    .then(valid => {
                        if (valid) {
                            if (this.button_clicked === 'Draft') {
                                this.updateStep();
                            } else if (this.button_clicked === 'Confirm') {
                                this.attest()
                            }
                        }
                    });
        },
        async init() {
            await this.getProcess();
            await this.getProcessSteps();
            if (this.attestor_step == this.current_step) {
                await this.getDefinitionAttributes(this.attestor_process_definition_step);
                await this.getLinkedProcesses();
                this.isCurrentStep = true;
                await this.getAttachments(this.step_to_update);
                await this.getAttributes(this.step_to_update);

            } else if (this.active_steps.length > 0) {
                this.isCurrentStep = false;
                await this.getDefinitionAttributes(this.active_steps[this.active_steps.length - 1].process_definition_step);
                await this.getAttachments(this.active_steps[this.active_steps.length - 1].process_step);
                await this.getAttributes(this.active_steps[this.active_steps.length - 1].process_step, this.active_steps[this.active_steps.length - 1].attested_at, this.active_steps[this.active_steps.length - 1].attestors, this.active_steps[this.active_steps.length - 1].name);

            }
        },
        async getProcess() {
            try {
                let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}`);
                this.process = data;
                this.current_step = data.current_step;
                this.process_status = data.status;
            } catch (e) {

            } finally {

            }
        },
        async getProcessSteps() {
            try {
                let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps`, {
                    params: {
                        page: 0,
                        per_page: 100
                    }
                });
                this.process_steps = data.process_steps;
                if (this.process_status !== 'Completed') {
                    this.process_steps.forEach(step => {
                        let found = step.attestors.find(sa => this.currentUser.did === sa.did && (step.status === 'Created' || step.status === 'Attested'));
                        if (found && found.did) {
                            this.attestor_process_definition_step = step.process_definition_step;
                            this.step_to_update = step.process_step;
                            this.attestor_step = step.order;
                            this.clicked_step_index = step.order;
                            this.process_step_status = step.status;
                            this.attested_at = step.status === 'Attested' ? step.attested : step.created;
                            this.attester = found.did.substring(2);
                        }
                    });
                } else if (this.process_status == 'Completed') {
                    this.process_step_status = 'Attested';
                    this.attestor_step = this.clicked_step_index = this.active_steps.length - 1;
                }
            } catch (e) {
                console.error(e);
                this.$toastr.e('Failed to get steps', 'Failed');
            }
        },
        async getDefinitionAttributes(definition_step_id) {
            if (definition_step_id) {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/${definition_step_id}/attributes`, {
                        params: {
                            page: 0,
                            per_page: 100
                        }
                    });
                    let self = this;
                    let found_definition_ref = data.process_definition_step_attributes.find(a=> a.definition_ref);
                    if(found_definition_ref){
                        this.linked_definition = found_definition_ref.definition_ref;
                    }
                    let definition_attributes = data.process_definition_step_attributes.map(a => {
                        let attribute = {
                            ...a,
                            fact: {
                                type: a.fact_type,
                                value: a.fact_type === 'Location' ? self.current_location : null
                            },
                            readonly: false,
                            repeatable: a.repeatable,
                            private: a.private,
                            values: a.options && typeof a.options === 'string' ? a.options.split(';') : []
                        };
                        return attribute;
                    });
                    // if (this.attestor_step !== this.current_step) {
                    let group_attributes = definition_attributes.filter(da => da.attribute_group);
                    this.definition_attributes_with_group = group_attributes;
                    let p = _.groupBy(group_attributes, da => da.attribute_group);
                    this.group_attributes = []
                    for (let key in p) {
                        if (key && p.hasOwnProperty(key)) {
                            let fields = p[key].map(f => ({
                                ...f,
                                name: f.name + '(1)',
                                attribute_group: key,
                                group_set_number: 1
                            }))
                            this.group_attributes.push({
                                group: key,
                                sets: [{fields: fields}]
                            })
                        }
                    }
                    // }
                    this.definition_attributes = definition_attributes.filter(da => !da.attribute_group);
                    this.repeatable_attributes_bkp = definition_attributes.filter(da => (!da.attribute_group && da.repeatable));
                    this.repeatable_attributes = this.repeatable_attributes_bkp.map(a => ({
                        ...a,
                        name: a.name + '(1)'
                    }));
                } catch (e) {
                    console.error(e);
                    this.$toastr.e('Failed to get attributes', 'Failed');
                }
            }
        },
        async getLinkedProcesses(){
            if(this.linked_definition) {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.linked_definition}/processes?page=0&per_page=1000`);
                    this.linked_processes = data.processes;
                } catch (e) {

                }
            }
        },
        addEntry(index) {
            let length = this.group_attributes[index].sets.length + 1;
            let new_entry = this.group_attributes[index].sets[0].fields.map(f => ({
                name: f.name.substring(0, f.name.lastIndexOf('(')) + '(' + length + ')',
                required: f.required,
                'fact': {
                    'type': f.fact.type,
                    'value': null
                },
                'readonly': f.readonly,
                'values': f.values,
                attribute_group: this.group_attributes[index].group,
                group_set_number: length,
                repeatable: f.repeatable,
                private: f.private,
            }))
            this.group_attributes[index].sets.push({fields: new_entry});
        },
        removeEntry(index1, index2, set) {
            this.group_attributes[index1].sets.splice(index2, 1);
            if (set.length > 0) {
                set.forEach(a => {
                    // this.attributes_to_be_removed.push({
                    //     attribute: a.attribute,
                    //     name: a.name,
                    //     type: this.getDatatypeForUpdate(a.fact.type),
                    //     value: a.fact.value
                    // })
                    this.attributes_to_be_removed.push(a.attribute)
                })
            }
        },
        async getAttachments(process_step) {
            try {
                let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${process_step}/documents`);
                this.process_attachments = data.process_attachment_list;
            } catch (e) {

            }
        },
        addRepeatable(a) {
            let index = a.name.substring(a.name.lastIndexOf('(') + 1, a.name.lastIndexOf(')'));
            index = parseInt(index) + 1;
            let entry = {
                name: a.name.substring(0, a.name.lastIndexOf('(')) + '(' + index + ')',
                required: a.required,
                'fact': {
                    'type': a.fact.type,
                    'value': null
                },
                'readonly': a.readonly,
                'values': a.values,
                repeatable: a.repeatable,
                private: a.private,
            };
            let index_to_put = this.repeatable_attributes.lastIndexOf(a);
            this.repeatable_attributes.splice(index_to_put + 1, 0, entry);
        },
        hide(a) {
            let index = a.name.substring(a.name.lastIndexOf('(') + 1, a.name.lastIndexOf(')'));
            index = parseInt(index) + 1;
            let next = a.name.substring(0, a.name.lastIndexOf('(')) + '(' + index + ')';
            let found = this.repeatable_attributes.find(a => a.name === next);
            if (found) {
                return false;
            } else {
                return true;
            }
        },
        getIndex(a) {
            let index = a.name.substring(a.name.lastIndexOf('(') + 1, a.name.lastIndexOf(')'));
            index = parseInt(index);
            return index;
        },
        removeRepeatable(index, a) {
            console.log(a)
            this.repeatable_attributes.splice(index, 1);
            if (a.attribute) {
                // this.attributes_to_be_removed.push({
                //     attribute: a.attribute,
                //     name: a.name,
                //     type: this.getDatatypeForUpdate(a.fact.type),
                //     value: a.fact.value
                // })
                this.attributes_to_be_removed.push(a.attribute)
            }

        },
        async getAttributes(process_step_id, attested_at, attestors, process_step) {
            if (!this.attested_at) {
                this.attested_at = attested_at;
            }
            if (!this.attester) {
                this.attester = attestors.length > 0 ? attestors[0].did.substring(2) : null;

            }
            this.current_step_name = process_step;
            if (process_step_id) {
                try {
                    this.removeLoader = false;
                    let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${process_step_id}/attributes`, {
                        params: {
                            page: 0,
                            per_page: 100,
                            sort_by: 'created',
                            order: 'ASC'
                        }
                    });
                    let self = this;
                    data.attributes = data.attributes.map(attr => {
                        let found = self.process_attachments.find(attachment => attachment.process_step === attr.process_step && attachment.label === attr.name);
                        if (found) {
                            attr['document_id'] = found.process_attachment
                            attr['document_name'] = found.name
                        }
                        let found_linked = self.definition_attributes.find(a=> attr.name.slice(0, attr.name.lastIndexOf('(')) === a.name && a.fact_type === 'Definition');
                        if(found_linked){
                            attr.fact.type = 'Definition'
                        }
                        return {
                            ...attr
                        }
                    });
                    if (this.isCurrentStep) {
                        this.definition_attributes.forEach(da => {
                            let found = data.attributes.find(a => da.name === a.name);
                            if (da.fact_type === 'File') {
                                da['file_name'] = 'Upload'
                            }
                            if (found && found.attribute) {
                                da.attribute = found.attribute;
                                da.fact.value = found.fact.value;
                                da['process_step'] = found.process_step ? found.process_step : null;
                                da['document_id'] = found.document_id ? found.document_id : null;
                                da['document_name'] = found.document_name ? found.document_name : null;
                            }
                        });
                        this.attributes = this.definition_attributes.filter(da => !da.attribute_group && !da.repeatable);
                        if (data.attributes.length > 0) {
                            let self = this;
                            let group_attributes = data.attributes.filter(da => da.attribute_group).map(a => {
                                let f = self.definition_attributes_with_group.find(da => da.name === a.name.substring(0, a.name.lastIndexOf('(')));
                                if (f) {
                                    return {
                                        attribute: a.attribute,
                                        name: a.name,
                                        required: f.required,
                                        'fact': {
                                            'type': f.fact.type === 'Float'? 'Text' : f.fact.type,
                                            'value': a.fact.value
                                        },
                                        'readonly': f.readonly,
                                        'values': f.values,
                                        attribute_group: a.attribute_group,
                                        group_set_number: a.group_set_number,
                                        repeatable: f.repeatable,
                                        private: f.private
                                    }
                                }
                            });
                            console.log(group_attributes)
                            let p = _.groupBy(group_attributes, da => da.attribute_group);
                            if (data.attributes.length > 0)
                                this.group_attributes = []
                            for (let key in p) {
                                if (key && p.hasOwnProperty(key)) {
                                    let fields = _.groupBy(p[key], pk => pk.group_set_number);
                                    let arr = [];
                                    for (let f in fields) {
                                        arr.push({
                                            fields: fields[f]
                                        })
                                    }
                                    this.group_attributes.push({
                                        group: key,
                                        sets: arr
                                    })
                                }
                            }
                            this.repeatable_attributes = [];
                            data.attributes.filter(da => !da.attribute_group).map(a => {
                                console.log(a)
                                let f = self.repeatable_attributes_bkp.find(da => da.name === a.name.substring(0, a.name.lastIndexOf('(')));
                                if (f) {
                                    this.repeatable_attributes.push({
                                        attribute: a.attribute,
                                        name: a.name,
                                        required: f.required,
                                        "fact": {
                                            "type": f.fact.type === 'Float'? 'Text' : f.fact.type,
                                            "value": a.fact.value
                                        },
                                        "readonly": f.readonly,
                                        "values": f.values,
                                        attribute_group: a.attribute_group,
                                        group_set_number: a.group_set_number,
                                        repeatable: f.repeatable,
                                        private: f.private
                                    })
                                }
                            });
                            console.log(JSON.stringify(this.repeatable_attributes))
                        }
                    } else {
                        let self = this;
                        data.attributes.forEach(a => {
                            let found = self.definition_attributes.find(da => a.name === da.name);
                            console.log(found)
                            if (found) {
                                a.fact.type = found.fact.type
                            }
                        })
                        this.attributes = data.attributes.filter(da => !da.attribute_group);
                        let group_attributes = data.attributes.filter(da => da.attribute_group);
                        let p = _.groupBy(group_attributes, da => da.attribute_group);
                        this.group_attributes = []
                        for (let key in p) {
                            if (key && p.hasOwnProperty(key)) {
                                let fields = _.groupBy(p[key], pk => pk.group_set_number);
                                let arr = [];
                                for (let f in fields) {
                                    arr.push({
                                        fields: fields[f]
                                    })
                                }
                                this.group_attributes.push({
                                    group: key,
                                    sets: arr
                                })
                            }
                        }
                    }
                } catch (e) {
                    console.error(e);
                    // this.$toastr.e('Failed to get attributes', 'Failed');
                } finally {
                    this.updating = false;
                    this.removeLoader = true;
                }
            }

        },

        getDatatypeForUpdate(type, val) {
            switch (type) {
                case 'Text':
                case 'Float':
                case 'Location':
                    return 'String';
                case 'Integer':
                case 'Definition':
                    return 'U128';
                case 'Yes/No':
                    return 'Bool';
                default:
                    return type;
            }
        },
        async updateStep() {
            if (!this.attesting) {
                EventBus.$emit('openLoader');
            }
            let attributes = this.attributes;
            this.group_attributes.forEach(g => {
                g.sets.forEach(s => {
                    s.fields.forEach(s => attributes.push(s))
                })
            });
            attributes = [...attributes, ...this.repeatable_attributes];
            let update_attributes = attributes.filter(a => (a.fact.value !== null) && a.attribute && a.fact.type !== 'File')
                    .map(ma => (
                            {
                                attribute: ma.attribute,
                                name: ma.name,
                                type: this.getDatatypeForUpdate(ma.fact.type, ma.fact.value ? ma.fact.value : null),
                                value: ma.fact.value.toString(),
                                attribute_group: ma.attribute_group ? ma.attribute_group : null,
                                group_set_number: ma.group_set_number ? ma.group_set_number : null,
                                repeatable: ma.repeatable ? ma.repeatable : null,
                                private: ma.private,
                            }
                    ));

            let add_attributes = attributes.filter(a => (a.fact.value !== null) && !a.attribute && a.fact.type !== 'File')
                    .map(ma => (
                            {
                                name: ma.name,
                                type: this.getDatatypeForUpdate(ma.fact.type, ma.fact.value ? ma.fact.value : null),
                                value: ma.fact.value.toString(),
                                attribute_group: ma.attribute_group ? ma.attribute_group : null,
                                group_set_number: ma.group_set_number ? ma.group_set_number : null,
                                repeatable: ma.repeatable ? ma.repeatable : null,
                                private: ma.private,
                            }
                    ));
            try {
                this.updating = true;
                await this.$provenance.patch(`/provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${this.step_to_update}`, {
                    add_attributes: add_attributes,
                    update_attributes: update_attributes,
                    remove_attributes: this.attributes_to_be_removed
                });
                await this.attachProcessDocs();
                if (!this.attesting) {
                    await this.getDefinitionAttributes(this.attestor_process_definition_step);
                    await this.getAttachments(this.step_to_update);
                    await this.getAttributes(this.step_to_update);
                    this.$toastr.s('Attribute(s) successfully updated', 'Success');
                }
            } catch (e) {
                this.$toastr.e('Process step updating failed', 'Failed');
            } finally {
                this.attributes_to_be_removed = [];
                if (!this.attesting) {
                    EventBus.$emit('closeLoader');
                }
            }
        },
        async attest() {
            try {
                EventBus.$emit('openLoader');
                this.attesting = true;
                await this.updateStep();
                await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${this.step_to_update}/attest`, {
                    process_definition_step_index: this.step_to_update
                });
                await this.attachProcessDocs();
                await this.init();
                this.$toastr.s('Process Step Successfully attested!', 'Success');
            } catch (e) {
                console.error(e);
            } finally {
                EventBus.$emit('closeLoader');
                this.attesting = false;
            }
        },

        async setStepIndex(i, process_definition_step, process_step, process_step_status, attested_at, attestors, process_step_name) {
            this.attributes = [];
            this.clicked_step_index = i;
            this.process_step_status = process_step_status;
            if (this.current_step == i) {
                this.isCurrentStep = true;
                await this.getAttachments(process_step);
                await this.getDefinitionAttributes(process_definition_step);
                await this.getAttributes(process_step, attested_at, attestors, process_step_name);
            } else {
                this.isCurrentStep = false;
                await this.getAttachments(process_step);
                await this.getDefinitionAttributes(process_definition_step);
                await this.getAttributes(process_step, attested_at, attestors, process_step_name);
            }
        },
        /*
        returns true if index of last OPEN status, else returns true if index is 0, otherwise false
         */
        shouldExpand(index) {
            let i = _.findLastIndex(this.active_steps, ['status', 'ATTESTED']);

            if (i !== -1) {
                return i === index;
            } else {
                return index === 0;
            }
        },
        shouldExpandGroup(index) {
            let i = 0;

            if (i !== -1) {
                return i === index;
            } else {
                return index === 0;
            }
        },
        async downloadAttachment(process_step, attachment_id, attachment_name) {
            try {
                let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${process_step}/documents/${attachment_id}`);
                let link = document.createElement('a');
                link.href = 'data:application/octet-stream;base64,' + data;
                link.download = attachment_name;
                link.click();
            } catch (e) {

            }
        },
        async removeAttachment(process_step, attachment_id, attribute, file_name) {
            try {
                EventBus.$emit('openLoader');
                await this.$provenance.delete(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${process_step}/documents/${attachment_id}`, {
                    data: {
                        attribute: attribute,
                        file_name: file_name
                    }
                });
                this.$toastr.s('Attachment successfully removed!', 'Success');
            } catch (e) {
                this.$toastr.e('Attachment removing failed', 'Failed');
            } finally {
                EventBus.$emit('closeLoader');
                await this.init();
            }
        },
    }
}
</script>

<style scoped>
.head {
    align-items: center;
}

.head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 2.1;
    display: block;
    color: #ffffff;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .head .number {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        background-color: #b4bdce;
        line-height: 2.1;
        display: block;
        color: #ffffff;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .head .number {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        background-color: #b4bdce;
        line-height: 3;
        display: block;
        color: #ffffff;
        text-align: center;
    }
}

@media only screen and (min-width: 769px) {
    .head .number {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        background-color: #b4bdce;
        line-height: 3.1;
        display: block;
        color: #ffffff;
        text-align: center;
    }
}

@media only screen and (max-width: 1366px) {
    .head .number {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        background-color: #b4bdce;
        line-height: 3;
        display: block;
        color: #ffffff;
        text-align: center;
    }
}

.head .number.active {
    background-color: #6675fa !important;
}

.head .title.active {
    color: #6675fa !important;
}

.custom-border-right {
    border-right: 1px solid #cdd4e0;
}

.no-border {
    border-left: none !important;
}

.custom-border {
    border: 1px solid #e9ecef !important;
}

fieldset {
    display: block !important;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 2px;
    border-style: groove;
    border-color: threedface;
    border-image: initial;
}

legend {
    display: block !important;
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
}
</style>